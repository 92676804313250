import { template as template_3b7b15c56db94b80bac37c56d69422f6 } from "@ember/template-compiler";
const SidebarSectionMessage = template_3b7b15c56db94b80bac37c56d69422f6(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
