import { template as template_9d6fe3decd9c4ef0bc67e2b3e59e057b } from "@ember/template-compiler";
import Component from "@glimmer/component";
export default class FKControlCustom extends Component {
    static controlType = "custom";
    static{
        template_9d6fe3decd9c4ef0bc67e2b3e59e057b(`
    <div class="form-kit__control-custom">
      {{yield}}
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
