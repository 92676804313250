import { template as template_c00b64377b6a47f997e9107eb329b82b } from "@ember/template-compiler";
const FKLabel = template_c00b64377b6a47f997e9107eb329b82b(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
