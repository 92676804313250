import { template as template_069045e9eff94870879e3ae7b2c84c33 } from "@ember/template-compiler";
const WelcomeHeader = template_069045e9eff94870879e3ae7b2c84c33(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
